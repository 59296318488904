
'use strict';

let search_ver = 'szukaj v4.1';

if (typeof(WP)==='undefined') {
    var WP = [];
}

var smethod = localStorage.getItem('searchMethod');
if (!smethod) {
    smethod = 1; // 1-internet, 2-WPH
    localStorage.setItem('searchMethod', 1);
}


// szukajka desktop WP wyniki Google: 1104565c538c5ad2b
// szukajka desktop WP wyniki WPH   : 65ca47a9668d6fa32
// szukajka desktop o2 wyniki Google: 87f0e3d6c98991621
// szukajka desktop o2 wyniki WPH   : 871c3b674bde73113

// wyniki mobile WP Google: 499d7241447df661a; Styl1 ID 2148213577
// wyniki mobile WP WPH: 4107a3db17939ffad; Styl2 ID 2834370969 
// wyniki mobile O2 Google: 571036af76f1cbb33; Styl1 ID 9835131274
// wyniki mobile O2 WPH: 391c542eb68ba753e; Styl2 ID 2283515463

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

var wpcx = '';
if (wp_sn.indexOf('o2') == -1) { //dla WP // smethod: 1-internet, 2-WPH
    if(!isMobile) {
        wpcx = (smethod == 1 ? '1104565c538c5ad2b' : '65ca47a9668d6fa32'); //// WP desktop////
    } else {         
        wpcx = (smethod == 1 ? '499d7241447df661a' : '4107a3db17939ffad'); //// WP mobile////    
    }
} else { //dla O2
    if(!isMobile) {
        wpcx = (smethod == 1 ? '87f0e3d6c98991621' : '871c3b674bde73113'); //// o2 desktop////
    } else {
        wpcx = (smethod == 1 ? '571036af76f1cbb33' : '391c542eb68ba753e'); //// o2 mobile////
    }
}

console.log(search_ver + ', CX: ' + wpcx + ', sn: ' + wp_sn);

WP.push(() => {
    WP.getScript({
        src: 'https://cse.google.com/cse.js?cx=' + wpcx,
        crossorigin: false,
    });

    WP.gdpr.runAfterConsent(() => {
        if (WP.gdpr.check('mr')) {

            try {    
                new google.ads.search.Ads( {...pageOptions, "styleId": styleId[1]}, adblock1 );
                new google.ads.search.Ads( {...pageOptions, "styleId": styleId[2]}, adblock2 );
                new google.ads.search.Ads( {...pageOptions, "styleId": styleId[3]}, adblock3 );
                //new google.ads.search.Ads( {...pageOptions, "styleId": styleId[3]}, rsblock1 );
            } catch (err) {
                // Log error
                console.log(err);
            }

            //_googCsa('ads', Object.assign(pageOptions, { styleId: styleId[1] }), adblock1, adblock2);

        }
    });
});

var query = '';
var qbody = document.getElementById('_jsLayout');
var initQuery = window.location.search.match('[?&]+q=([^&]+)(&start=([0-9]+))?');

if (initQuery && initQuery.length) {

    query = decodeURIComponent(initQuery[1].replace(/\+/g, ' '));
    let sitepos = query.indexOf('site');
    if (sitepos == -1) {
        document.getElementById('searchForm').q.value = query.substring(0);
    } else {
        document.getElementById('searchForm').q.value = query.substring(0, sitepos - 1);
    }
    qbody.classList.remove('outer');
}
console.log('initQuery' + initQuery);


if (smethod == 1) {

}

// formularz //
var sform = document.getElementById('searchForm');
if (sform && sform.length) {
    //
    if (sform.elements.area && sform.elements.area.length > 1) {
        for (var i = 0; i < sform.elements.area.length; i++) {
            //init
            if (smethod == i + 1) sform.elements.area[i].checked = 1;
            sform.elements.area[i].addEventListener('change', function (e) {

                smethod = e.target.value;
                localStorage.setItem('searchMethod', smethod);
                sform.submit();
                //dispatchEvent(new Event('submit'));

            });
        };
    } else {
        console.log('BACKEND: BRAK TABÓW WP/GOOGLE W KODZIE!');
    }    
}




//fixed nav po zescrolowaniu
var toBeFixedElement = document.getElementsByClassName('search-form--wrapper');
var checkFixedSearch = function () {
    if (document.body.scrollTop >= 30 || document.documentElement.scrollTop >= 30) {
        toBeFixedElement[0].classList.add("_jsFixed");
    } else {
        toBeFixedElement[0].classList.remove('_jsFixed');
    }

    setTimeout(checkFixedSearch, 450);
};
setTimeout(checkFixedSearch, 450);


// stats
function sendStats(category, action, label, value) {
    if (value) {
        WP.stat.dot(wp_sn + "_dynamic", {
            "category": category,
            "label": label,
            "value": value,
            "action": action
        }, 'top');
        ga('send', 'event', category, action + '-' + value, label);
        console.log(category + ', ' + action + ", " + label + ", " + value);
    } else {
        WP.stat.dot(wp_sn + "_dynamic", {
            "category": category,
            "label": label,
            "action": action
        }, 'top');
        ga('send', 'event', category, action, label);
        console.log(category + ', ' + action + ", " + label);
    }
}

//
// _adsense.js
//

var q = unescape(decodeURIComponent(getAllUrlParams().q));


function getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }
    return obj;
}


function showLinks(divName) {
    var new_h3 = document.createElement('h3');
    new_h3.className = 'section--head';
    new_h3.innerHTML = 'Linki sponsorowane';
    var reference = document.getElementById(divName);
    reference.parentNode.insertBefore(new_h3, reference);
}


function separator(where, divName) {
    var new_div = document.createElement('div');
    new_div.className = 'bx';
    var inner_div = document.createElement('div');
    inner_div.className = 'content spacer';
    new_div.appendChild(inner_div);
    var reference = document.getElementById(divName);
    if (where == 0) {
        reference.parentNode.insertBefore(new_div, reference);
    }
    if (where == 1) {
        if (reference.nextSibling) {
            reference.nextSibling.parentNode.insertBefore(new_div, reference.nextSibling);
        }
    }

}


///////////////////////////////////////////////////////////////

/* PageOptions */

/*
kontenery na WP:
górny : identyfikator stylu 6005536292
dolny : identyfikator stylu 6531627697
prawy: identyfikator stylu 8870501943

kontenery na O2:
górny : identyfikator stylu 2066289383
dolny : identyfikator stylu 8910315094
prawy: identyfikator stylu 4246281479
*/

var styleId = [];
if (wp_sn.indexOf('o2') == -1) {
     if(!isMobile) {
        styleId = ["", "6005536292", "6531627697", "8870501943"]; //// WP ////
     } else {
        styleId = ["", "2834370969", "2834370969", "2834370969"]; //// WP mobile ////
     }
} else {
    if(!isMobile) {
        styleId = ["", "2066289383", "8910315094", "4246281479"]; //// o2 ////
    } else {
        styleId = ["", "2283515463", "2283515463", "2283515463"]; //// o2 mobile ////
    }
}

var pageOptions = {
    'pubId': 'pub-9335149169362677',
    'query': q,
    'hl': 'pl',
    'oe': 'utf8',
    'ie': 'utf8',
    'waitForAds': false,
    'clickToCall': true,
    'siteLinks': true,
    'instantPreviews': true,
    'sellerRatings': true,
    'adPage': 1,
    'domainLinkAboveDescription': true,
    'channel': 'sg_wp',
    'adtest': 'off',
    'styleId': '', // Enter your style ID
    'numRepeated': 3, // rolled out to 100% of your users 
    'referrerAdCreative': 'szukaj więcej', // Only used when a user clicked an ad to arrive here
    'resultsPageBaseUrl': '//www.szukaj.wp.pl', // Enter the base URL of your results page    
};

/* górny */
var adblock1 = {
    'container': 'SLT',
    'adLoadedCallback': function (containerName, adsLoaded) {
        if (adsLoaded) {
            try {
                showLinks('SLT');
                separator(1, 'SLT');
            } catch (e) {}
        }
    },
    'maxTop': 4,

};

/* dolny */
var adblock2 = {
    'container': 'SLB',
    'adLoadedCallback': function (containerName, adsLoaded) {
        if (adsLoaded) {
            try {
                separator(0, 'SLB');
                showLinks('SLB');
            } catch (e) {}
        }
    },
    'number': 3,
    'width': 'auto',

};

/* prawy linki dolne*/
var adblock3 = {
    'container': 'SLR',
    'adLoadedCallback': function (containerName, adsLoaded) {
        if (adsLoaded) {
            try {
                showLinks('SLR');
            } catch (e) {}
        }
    },
    'number': 4,
};

/* prawy boksy górne */
var rsblock1 = {
    'container': 'AFS',
    'adLoadedCallback': function (containerName, adsLoaded) {
        if (adsLoaded) {
            try {
                showLinks('AFS');
            } catch (e) {}
        }
    },
    'relatedSearches': 6,

};

